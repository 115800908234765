/* Start of Header.css */


.header-component {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  /* background-color: #f8e8fc; */
  background-image: url("/public/headerbkg.jpg");
  box-shadow: 0px 9px 13px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
}

.header-component img {
  height: 50px;
  width: 50px;
  
}

.header-component h1 {
  font-size: 36px;
  font-weight: 700;
  color: rgb(254, 255, 220);
  text-shadow: 1px 1px 4px pink;
  margin-left: 15px;
  text-align: right;
 
}

.header-component p {
  color: #674836;
}

.header-component a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.header-component .menu {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.header-component .menu a {
  position: relative;
  text-decoration: underline;
  font-size: 24px;
  color: #674836;
}

.header-component .menu img {
  height: 40px;
  width: 40px;
}

.header-component .menu a > img {
  margin-right: 8px;
}

.header-component .menu .badge {
  position: absolute;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  font-size: 14px;
  background-color: #CC8B18;
  color: #FFF;
  font-weight: normal;
  right: 2px;
  top: calc(50% - 22px);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .header-component {
      flex-direction: row;
      align-items: center;
      padding: 0 50px;
  }

  .header-component img {
      height: 90px;
      width: 90px;
  }

  .header-component .menu {
      width: auto;
      flex-grow: 1;
      height: 100%;
      margin: 0;
  }
}




/* Start of Below-Header.css */



.below-header-component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.below-header-component > div:first-child {
  padding: 0 20px;
}


/* Start of menu- withing Below-Header.css */


.menu-component {
  
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  position: fixed;
  width: 100vw;
  overflow: scroll;
  bottom: 0;
 
}

.menu-component img {
  height: 75px;
  width: 75px;
}

.menu-component .thumbnail-component:last-child {
  margin-right: 20px;
}

.menu-component .thumbnail-component:first-child {
  margin-left: 20px;
}

.menu-component .thumbnail-component:nth-child(3n + 2) div {
  background-color: #9BA699;
}

.menu-component .thumbnail-component:nth-child(3n + 3) div {
  background-color: #3F3F40;
}

@media (min-width: 768px) {

  .below-header-component {
      flex-direction: row-reverse;
      padding: 0 20px;
  }

  .below-header-component > div:first-child {
      padding: 0 20px;
      flex: 1;
      
  }


  .menu-component {
      position: relative;
      flex-direction: column;
      row-gap: 16px;
      width: 100px;
      height: 100vh;
      overflow: scroll;
      
  }

  .menu-component .thumbnail-component:last-child,
  .menu-component .thumbnail-component:first-child   {
      margin: 0;
  }

  .menu-component img {
      height: 106px;
      width: 106px;
  }
}


/* End of below Header */



/* Start of Main.css */

.main-component {
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
}

.main-component img {
  width: 250px;
  height: 250px;
  filter: invert(75%);
}

.main-component h2 {
  font-size: 36px;
  font-weight: normal;
  margin: 0;
}

.main-component h6 {
  margin: 0;
}

.main-component div {
  font-size: 18px;
  font-weight: normal;

}

.main-component label {
  font-size: 18px;
  font-weight: normal;
 
}

.main-component input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;

}

.main-component button {
  width: max-content;
  background-color: #c46fb6;
  color: rgb(254, 255, 220);
  font-size: 21px;  
  padding: 10px 15px;
  border-radius: 20px;
  margin: 20px 0;
  border: none;
  cursor: pointer;
  box-shadow: 0px 9px 13px rgb(0 0 0 / 10%);
}

.main-component button:hover {
  opacity: 75%;
}

@media (min-width: 768px) {
  .main-component {
      padding: 40px 50px;
      width: 200px;
  }

  .main-component img {
      width: 400px;
      height: 400px;
  }
}


/* End of Main.css */




/* thumbnail describes each menu item */


.thumbnail-component {
display: flex;
flex-direction: column;
width: 100%;
text-decoration: none;
color: rgb(254, 255, 220);
}

.thumbnail-component div {
/* background-color: #c46fb6; */
background-image: url("/public/buttonbkg.jpg");
border-radius: 20px;
display: flex;
justify-content: center;
align-items: center;
padding: 10px 15px;
box-shadow: 0px 9px 13px rgb(0 0 0 / 10%);
}
.thumbnail-component:hover {
  opacity: 75%;
}

.thumbnail-component img {
height: 106px;
width: 106px;
transition: transform 333ms ease;
}

.thumbnail-component:hover img {
transform: scale(1.2);
}

.thumbnail-component p {
  
display: flex;
width: 100%;
justify-content: center;
align-items: center;
margin: 9px 0 0;
font-size: 21px;
text-align: center;
white-space: nowrap;
}